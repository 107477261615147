import * as React from 'react';
import SmallHeader from 'styleguide/components/Typography/SmallHeader/SmallHeader';
import { media } from 'styleguide/helpers/media';
import HorizontalRecipeCard from 'styleguide/components/Cards/HorizontalRecipeCard/HorizontalRecipeCard';
import RecipeCard from 'styleguide/components/Cards/RecipeCard/RecipeCard';
import styled, { css } from 'styled-components';
import { RecipeCardInterface } from 'styleguide/interfaces/cardInterfaces';
import { FormattedMessage, useIntl } from 'react-intl';
import { WeeklyMenuDay, DayOfWeek } from '../interfaces';
import injectRoutable, { InjectedRoutableProps } from '../../Routing/Routable';

const WeeklyMenuRecipesWrapper = styled.section<{ isLoading?: boolean; isStatic?: boolean }>`
	${props =>
		props.isStatic
			? css`
					display: none;

					${media.tablet`
			display: block;
		`};
			  `
			: ''};

	${props => (props.isLoading ? 'opacity: 0.5' : '')};

	max-width: 1080px;
	margin: 0 auto;

	.recipe-card-image-wrapper {
		/* IE */
		flex-basis: 132px;
		flex-basis: auto;
	}
`;

const ulReset = `
  margin: 0;
  padding: 0;
  list-style: none;
`;

const WeekdayRecipeList = styled.ul`
	${ulReset};
	${media.tablet` 
    display: flex;
		flex-direction: row;
    justify-content: center;
    align-items: stretch;
  `};
`;

const WeekdayRecipeListItem = styled.li`
	text-align: center;
	display: flex;
	flex-direction: column;

	${media.desktop`
    flex: 1 0 0px;
  `};

	& + & {
		margin: 30px 0 0;

		${media.tablet`
      margin: 0 0 0 5px;
    `};

		${media.desktop`
      margin: 0 0 0 10px;
    `};
	}

	.day-header {
		margin-bottom: 10px;
	}

	.recipe-card {
		flex: 1;

		/* IE */
		flex-basis: 168px;
		flex-basis: auto;
		min-height: 168px;
		max-width: 208px;

		img {
			max-height: 132px;

			${media.tablet`
        min-height: 115px;      
      `};
		}
	}
`;

const WeekendRecipeList = styled.ul`
	${ulReset};
	margin-top: 30px;

	${media.desktop`
    display: flex;
		flex-direction: row;
  `};
`;

const WeekendRecipeListItem = styled.li`
	& + & {
		margin: 30px 0 0;

		${media.desktop`
      margin: 0 0 0 10px;
    `};
	}

	${media.desktop`
		/* The first one is for IE */
    flex: 1 0 535px;
    flex: 1 0 calc(50% - 5px);
  `};

	.day-header {
		text-align: center;
		margin: 0 0 10px;

		${media.tablet`
      margin-left: 20px;
      text-align: left;
    `};
	}
`;

const WeekendMenuWrapper = styled.div`
	.recipe-card {
		max-width: 208px;

		img {
			max-height: 132px;
		}

		${media.tablet`
      flex: 1 1 20%;
    `};

		${media.desktop`
      flex: 1 1 auto;
    `};
	}

	${media.tablet`
    display: flex;
  `};
`;

const WeekendSidesWrapper = styled.div`
	margin-top: 10px;

	${media.tablet`
    flex: 2 0 auto;
    margin-top: 0;
    margin-left: 10px;

		.horizontal-recipe-card {
			max-width: 320px;
		}
  `};
`;

const EmptyDayNotice = styled.div`
	margin-top: 15px;

	${media.tablet`
		margin-top: 50px;
	`};
`;

const getImageUrl = (url: string) => {
	return `${url}/358x231-recipe-highlight`;
};

export interface WeeklyMenuRecipesProps {
	days: WeeklyMenuDay[];
	isLoading?: boolean;
	isStatic?: boolean;
}

const WeeklyMenuRecipes: React.FC<WeeklyMenuRecipesProps & InjectedRoutableProps> = ({
	days,
	onLinkClick,
	isLoading,
	isStatic,
}) => {
	const intl = useIntl();

	const getWeekendRecipe = (recipe: RecipeCardInterface | null, badgeText: string): RecipeCardInterface | null => {
		if (!recipe) {
			return null;
		}

		return {
			...recipe,
			badgeText,
			imageUrl: getImageUrl(recipe.imageUrl),
		};
	};

	const getWeekendRecipes = (day: WeeklyMenuDay): RecipeCardInterface[] => {
		return [
			getWeekendRecipe(day.starter, intl.formatMessage({ id: 'kitchenmenu_starter_recipe' })),
			getWeekendRecipe(day.dessert, intl.formatMessage({ id: 'kitchenmenu_dessert_recipe' })),
		].filter(r => r && r.recipeName) as RecipeCardInterface[];
	};

	const weekdays = days.slice(0, 5);
	const weekend = days.slice(5);

	return (
		<WeeklyMenuRecipesWrapper isLoading={isLoading} isStatic={isStatic}>
			<WeekdayRecipeList>
				{weekdays.map(day => (
					<WeekdayRecipeListItem key={day.title}>
						<SmallHeader className="day-header">{day.title}</SmallHeader>
						{!day.maindish && <EmptyDayNotice>tälle päivälle ei ole suositeltavaa annosta</EmptyDayNotice>}

						{day.maindish && (
							<HorizontalRecipeCard
								recipesData={[{ ...day.maindish, imageUrl: getImageUrl(day.maindish?.imageUrl) }]}
								onClick={onLinkClick}
								isVisible={{ default: true, tablet: false }}
							/>
						)}
						{day.maindish && (
							<RecipeCard
								{...day.maindish}
								imageUrl={getImageUrl(day.maindish?.imageUrl)}
								onClick={onLinkClick}
								isVisible={{ default: false, tablet: true }}
							/>
						)}
					</WeekdayRecipeListItem>
				))}
			</WeekdayRecipeList>
			<WeekendRecipeList>
				{weekend.map(day => (
					<WeekendRecipeListItem key={day.title}>
						<SmallHeader className="day-header">
							<FormattedMessage
								id={day.dayOfWeek === DayOfWeek.Saturday ? 'kitchenmenu_saturday_menu' : 'kitchenmenu_sunday_menu'}
							/>
						</SmallHeader>
						<WeekendMenuWrapper>
							{day.maindish && (
								<HorizontalRecipeCard
									recipesData={[{ ...day.maindish, imageUrl: getImageUrl(day.maindish.imageUrl) }]}
									isVisible={{ default: true, tablet: false }}
								/>
							)}
							{day.maindish && (
								<RecipeCard
									{...day.maindish}
									imageUrl={getImageUrl(day.maindish.imageUrl)}
									onClick={onLinkClick}
									isVisible={{ default: false, tablet: true }}
								/>
							)}
							<WeekendSidesWrapper>
								<HorizontalRecipeCard
									recipesData={getWeekendRecipes(day)}
									isSmall={true}
									className="horizontal-recipe-card"
								/>
							</WeekendSidesWrapper>
						</WeekendMenuWrapper>
					</WeekendRecipeListItem>
				))}
			</WeekendRecipeList>
		</WeeklyMenuRecipesWrapper>
	);
};

export default injectRoutable(WeeklyMenuRecipes);
