import * as React from 'react';
import IconButton from 'styleguide/components/Buttons/IconButton/IconButton';
import ArrowLeftIcon from 'styleguide/components/Icons/NormalIcons/ArrowLeftIcon/ArrowLeftIcon';
import ArrowRightIcon from 'styleguide/components/Icons/NormalIcons/ArrowRightIcon/ArrowRightIcon';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import styled, { ThemeContext } from 'styled-components';

const WeeklyMenuNavWrapper = styled.nav`
	display: flex;
	align-items: center;
	justify-content: center;

	.nav-button {
		border: 1px solid ${props => props.theme.colors.border};
		width: 24px;
		height: 24px;
		cursor: pointer;
	}

	.nav-button--disabled {
		cursor: default;
	}

	.medium-header {
		margin: 8px 20px;
	}
`;

export interface WeeklyMenuNavProps {
	weekName: string;
	hasNextWeek: boolean;
	hasPreviousWeek: boolean;
	onNextWeekClick: () => void;
	onPreviousWeekClick: () => void;
}

const WeeklyMenuNav: React.FC<WeeklyMenuNavProps> = ({
	weekName,
	hasNextWeek,
	hasPreviousWeek,
	onNextWeekClick,
	onPreviousWeekClick,
}) => {
	const theme = React.useContext(ThemeContext);

	const renderLeftIcon = (isEnabled: boolean) => {
		return <ArrowLeftIcon color={isEnabled ? undefined : theme.colors.buttonDisabled} size={12} />;
	};

	const renderRightIcon = (isEnabled: boolean) => {
		return <ArrowRightIcon color={isEnabled ? undefined : theme.colors.buttonDisabled} size={12} />;
	};

	const getIconClassNames = (isDisabled: boolean) => {
		return `nav-button ${isDisabled ? 'nav-button--disabled' : ''}`;
	};

	return (
		<WeeklyMenuNavWrapper>
			<IconButton
				icon={renderLeftIcon(hasPreviousWeek)}
				className={getIconClassNames(!hasPreviousWeek)}
				onClick={onPreviousWeekClick}
			/>
			<MediumHeader className="medium-header">{weekName}</MediumHeader>
			<IconButton
				icon={renderRightIcon(hasNextWeek)}
				className={getIconClassNames(!hasNextWeek)}
				onClick={onNextWeekClick}
			/>
		</WeeklyMenuNavWrapper>
	);
};

export default WeeklyMenuNav;
