import * as React from 'react';
import WeeklyMenuNav from './components/WeeklyMenuNav';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import OutlinedButtonLink from 'styleguide/components/Buttons/OutlinedButton/OutlinedButtonLink';
import WeeklyMenuRecipes from './components/WeeklyMenuRecipes';
import { FormattedMessage } from 'react-intl';
import { WeeklyMenuState } from './interfaces';
import injectRoutable, { InjectedRoutableProps } from '../Routing/Routable';
import WeeklyMenuIcon from './components/WeeklyMenuIcon';

const WeeklyMenuWrapper = styled.article`
	padding: 30px 0;
	position: relative;
	z-index: ${props => props.theme.zIndices.titleBlockTitle};
	width: 100%;

	${media.desktop`
		padding: 30px 30px 50px;
	`};

	& > .medium-header {
		margin: 8px auto;
		text-align: center;
	}

	.kitchen-menu-link {
		margin: 0 auto;
		width: auto;
		max-width: 320px;
		padding: 0 20px;

		${media.tablet`
			margin-top: 40px;
		`};

		.kitchen-menu-link__label--mobile {
			${media.tablet`
				display: none;
			`};
		}
		.kitchen-menu-link__label--tablet {
			display: none;

			${media.tablet`
				display: block;
			`};
		}
	}
`;

const WeeklyMenuDescription = styled.p`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 18px;
	line-height: 21px;
	color: ${props => props.theme.colors.bodyText};
	padding: 0 30px;
	margin: 20px auto 50px;
	max-width: 750px;
	text-align: center;

	${media.tablet`
		padding: 0 50px;
	`};

	${media.desktop`
		padding: 0;
	`};
`;

const StaticMobileIconWrapper = styled.div`
	text-align: center;

	svg {
		width: 60%;
		height: auto;
		margin: 20px auto;
	}

	${media.tablet`
		display: none;
	`};
`;

export interface WeeklyMenuProps extends WeeklyMenuState {
	isStatic?: boolean;
	onGoToWeek?: (url: string) => void;
	siteUrlPrefix: string;
}

class WeeklyMenu extends React.Component<WeeklyMenuProps & InjectedRoutableProps, {}> {
	public render() {
		const { weeklyMenu, isStatic, isLoading, onLinkClick, siteUrlPrefix } = this.props;

		if (!weeklyMenu) {
			return null;
		}

		const { title, nextWeekUrl, previousWeekUrl, description, days, weekNumber } = weeklyMenu;

		return (
			<WeeklyMenuWrapper>
				{isStatic && (
					<>
						<MediumHeader className="medium-header">
							<FormattedMessage id="kitchenmenu_menu_for_week" values={{ weekNumber }} />
						</MediumHeader>
						<StaticMobileIconWrapper>
							<WeeklyMenuIcon />
						</StaticMobileIconWrapper>
					</>
				)}
				{!isStatic && (
					<WeeklyMenuNav
						weekName={title}
						hasNextWeek={Boolean(nextWeekUrl)}
						hasPreviousWeek={Boolean(previousWeekUrl)}
						onNextWeekClick={this.onNextWeekClick}
						onPreviousWeekClick={this.onPreviousWeekClick}
					/>
				)}

				{description && <WeeklyMenuDescription>{description}</WeeklyMenuDescription>}

				<WeeklyMenuRecipes days={days} isLoading={isLoading} isStatic={isStatic} />

				{isStatic && (
					<OutlinedButtonLink
						href={`${siteUrlPrefix}reseptit/ruokalista/`}
						className="kitchen-menu-link"
						onClick={onLinkClick}>
						<span className="kitchen-menu-link__label--mobile">
							<FormattedMessage id="kitchenmenu_view_weekly_menu" />
						</span>
						<span className="kitchen-menu-link__label--tablet">
							<FormattedMessage id="kitchenmenu_view_other_weekly_menus" />
						</span>
					</OutlinedButtonLink>
				)}
			</WeeklyMenuWrapper>
		);
	}

	private onPreviousWeekClick = () => {
		const { weeklyMenu, onGoToWeek } = this.props;
		if (weeklyMenu && weeklyMenu.previousWeekUrl && onGoToWeek) {
			onGoToWeek(weeklyMenu.previousWeekUrl);
		}
	};

	private onNextWeekClick = () => {
		const { weeklyMenu, onGoToWeek } = this.props;
		if (weeklyMenu && weeklyMenu.nextWeekUrl && onGoToWeek) {
			onGoToWeek(weeklyMenu.nextWeekUrl);
		}
	};
}

export default injectRoutable(WeeklyMenu);
