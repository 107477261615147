import { RecipeCardInterface } from 'styleguide/interfaces/cardInterfaces';
import { AsyncLoadableState } from '../../interfaces/common';

export enum DayOfWeek {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
}

export interface WeeklyMenuDay {
	title: string;
	dayOfWeek: DayOfWeek;
	starter: RecipeCardInterface | null;
	maindish: RecipeCardInterface | null;
	dessert: RecipeCardInterface | null;
}

export interface WeeklyMenuContent {
	title: string;
	description: string | null;
	nextWeekUrl: string;
	previousWeekUrl: string;
	year: number;
	weekNumber: number;
	days: WeeklyMenuDay[];
}

export interface WeeklyMenuState extends AsyncLoadableState {
	weeklyMenu?: WeeklyMenuContent;
}
